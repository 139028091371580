<template>
  <div id="screenPage" class="container faq">
    <div class="hero">
      <h4 class="title is-4">F.A.Q.</h4>
    </div>
    <section style="max-width: 500px; margin: auto; text-align: left">
      <div
        v-for="(collapse, index) of collapses"
        :key="index"
        @click="toggle(index)"
      >
        <p class="is-size-6" :class="{active: openedIndex === index}">
          <span>{{ collapse.title }}</span>
          <i
            v-if="openedIndex === index"
            class="fal fa-chevron-down is-medium"
          ></i>
          <i
            v-if="openedIndex != index"
            class="fal fa-chevron-up is-medium"
          ></i>
        </p>
        <div
          class="content"
          v-if="openedIndex === index"
          v-html="collapse.text"
        ></div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  data() {
    return {
      openedIndex: 0,
      collapses: [
        {
          title: 'What is London Elegance Club ?',
          text: 'A project aiming to simplify, expand, learn and make your life more interesting in your own way. You can learn a new habit or try a challenge, meditation journey, whatever works for you.',
        },
        {
          title: 'Is London Elegance Club Free ?',
          text: "Once you become a member, you'll have a lifetime access for Free!",
        },
        {
          title: 'When LEC was launched',
          text: '2021, but it was in the making for a very long time',
        },
        {
          title: 'How to install it on mobile devices as an App ?',
          text: 'It\'s buit as an Universal App also known as PWA. That means you can use it anywhere! The main difference between viewing website from installed as an app - you don\'t have a browser top. You can find a guide here: <a href="https://medium.com/progressivewebapps/how-to-install-a-pwa-to-your-device-68a8d37fadc1">Install a pwa to your device</a>',
        },
        {
          title: 'Do we support "Push notifications" ?',
          text: "We don't have any plans for this feature, since it's known as distracting, taking away your concentration from whatever you're doing. We built our app as a tool to help you, instead of distracting you with push notifcations.",
        },
        {
          title: 'My friend wants to join this project',
          text: "We don't have an open registration. You need an single use invitation in order to create an account. We're aiming for great people instead of just quantity.",
        },
      ],
    }
  },
  methods: {
    toggle(index) {
      this.openedIndex = index
    },
  },
}
</script>
<style scoped lang="scss">
p {
  position: relative;
  background: #f9f9f9;
  border: 1px solid #ebebeb;
  border-bottom: 0px;
  &.active span{
    font-weight: 600;
  }
  span {
    cursor: pointer;
    font-weight: 400;
    line-height: 40px;
    font-size: 14px !important;
    padding: 0 5px;
  }
  i {
    color: #b4b4b4;
    padding: 0;
    margin-left: 10px;
    font-size: 12px;
    position: absolute;
    right: 5px;
    top: 15px
  }
  /* text-align: left; */
}
.fal.fa-chevron-up {
  background: none;
  /* height: 24px; */
  /* margin-left: 10px; */
}

div.content {
  background: #fff5e5;
  border: 1px solid #ffecce;
  padding: 10px;
  font-size: 14px;
}
</style>