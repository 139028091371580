<template>
  <div id="screenPage" class="contactus container">
    <div class="hero">
      <h4 class="title is-4">Contact Us</h4>
    </div>
    <section class="max500 column">
      <b-field horizontal label="Topic">
        <van-dropdown-menu class="height34dropdown" style="margin-bottom: 15px">
          <van-dropdown-item v-model="item.section" :options="sections" />
        </van-dropdown-menu>
      </b-field>

      <b-field horizontal label="Message">
        <b-input v-model="item.issues" type="textarea"></b-input>
      </b-field>

      <b-field horizontal>
        <!-- Label left empty for spacing -->
        <p class="control">
          <button @click="submit" class="button is-primary">Send message</button>
        </p>
      </b-field>
    </section>
  </div>
</template>
<script>
import ApiService from '@/services/ApiService'
export default {
  name: 'ContactUs',
  components: {},
  props: {
    // id: Number
  },
  data() {
    return {
      item: {
        like: '',
        dlike: '',
      },
      sections: [
        { text: 'General', value: 'general' },
        { text: 'Style', value: 'style' },
        { text: 'Beauty', value: 'beauty' },
      ],
    }
  },
  mounted() {},
  methods: {
    submit() {
      ApiService.feedback.submitFeedback(this.item).then(() => {
        this.item = {}
        this.$notify({
          type: 'success',
          message: 'Thank you for contacting us!',
        })
      })
    },
  },
}
</script>