<template>
  <div class="about">
    <div class="hero">
      <h4 class="title is-4">Avatar generator</h4>
    </div>
    <section style="padding: 10px; margin: auto; max-width: 500px">
      <div class="columns">
        <div id="pic" class="column">
          <avataaars
            :clothe-type="clotheTypes[clotheType]"
            :clothe-color="clotheColors[clotheColor]"
            :eyebrow-type="eyebrows[eyebrow]"
            :eye-type="eyeTypes[eyeType]"
            :mouth-type="mouthTypes[mouthType]"
            :hair-color="hairColors[hairColor]"
            :skin-color="skinColors[skinColor]"
            :accessories-type="accessoriesTypes[accessoriesType]"
            :top-type="topTypes[topType]"
            facial-hair-type="Blank"
            :graphic-type="'Blank'"
          >
          </avataaars>
        </div>
        <div class="column">
          <small>Mouth</small>
          <b-slider
            v-model="mouthType"
            :min="1"
            :max="7"
            rounded
            sized="is-large"
            type="is-warning"
          ></b-slider>
          <small>Eyebrow Type</small>
          <b-slider
            v-model="eyebrow"
            :min="1"
            :max="10"
            rounded
            sized="is-large"
            type="is-warning"
          ></b-slider>
          <small>Clothe Type</small>
          <b-slider
            v-model="clotheType"
            :min="1"
            :max="8"
            rounded
            sized="is-large"
            type="is-warning"
          ></b-slider>
          <small>Clothe Color</small>
          <b-slider
            v-model="clotheColor"
            :min="1"
            :max="14"
            rounded
            sized="is-large"
            type="is-warning"
          ></b-slider>
          <small>Eye Type</small>
          <b-slider
            v-model="eyeType"
            :min="1"
            :max="11"
            rounded
            sized="is-large"
            type="is-warning"
          ></b-slider>
          <small>Eyewear</small>
          <b-slider
            v-model="accessoriesType"
            :min="1"
            :max="7"
            rounded
            sized="is-large"
            type="is-warning"
          ></b-slider>
          <small>Skin color</small>
          <b-slider
            v-model="skinColor"
            :min="1"
            :max="7"
            rounded
            sized="is-large"
            type="is-warning"
          ></b-slider>
          <small>Hair color</small>
          <b-slider
            v-model="hairColor"
            :min="1"
            :max="9"
            rounded
            sized="is-large"
            type="is-warning"
          ></b-slider>
          <small>Hair type</small>
          <b-slider
            v-model="topType"
            :min="1"
            :max="30"
            rounded
            sized="is-large"
            type="is-warning"
          ></b-slider>
        </div>
      </div>
      <hr />
      <div>
        <b-button type="is-primary" @click="savePng">Save</b-button>
      </div>

      <b-field></b-field>
    </section>
  </div>
</template>
<script>
import ApiService from '@/services/ApiService'
import { svgAsPngUri } from 'save-svg-as-png'
import Avataaars from 'vuejs-avataaars'
import avatarOptions from '@/constants/avatarOptions.json'
export default {
  components: {
    Avataaars,
  },
  data() {
    return {
      mouthType: 1,
      eyebrow: 1,
      clotheType: 1,
      clotheColor: 1,
      eyeType: 1,
      accessoriesType: 1,
      skinColor: 1,
      hairColor: 1,
      topType: 1,
      eyebrows: avatarOptions.eyebrows,
      clotheTypes: avatarOptions.clotheTypes,
      clotheColors: avatarOptions.clotheColors,
      mouthTypes: avatarOptions.mouthTypes,
      eyeTypes: avatarOptions.eyeTypes,
      accessoriesTypes: avatarOptions.accessoriesTypes,
      skinColors: avatarOptions.skinColors,
      hairColors: avatarOptions.hairColors,
      topTypes: avatarOptions.topTypes,
    }
  },
  mounted() {
    this.getUserData()
  },
  methods: {
    savePng() {
      const options = {
        encoderOptions: 0.8,
        scale: 0.8,
      }
      svgAsPngUri(document.getElementsByTagName('svg')[0], options).then(
        (uri) => {
          ApiService.upload.saveAvatar({
            image: uri,
            avatar_options: {
              mouthType: this.mouthType,
              eyebrow: this.eyebrow,
              clotheType: this.clotheType,
              clotheColor: this.clotheColor,
              eyeType: this.eyeType,
              accessoriesType: this.accessoriesType,
              skinColor: this.skinColor,
              hairColor: this.hairColor,
              topType: this.topType,
            },
          }).then(() => {
            this.$router.push('/members/my_profile');
          })
        }
      )
    },
    getUserData() {
      ApiService.registration.getUserData().then((resp) => {
        this.data = resp.data
        if (this.data.avatar_options && Object.keys(this.data.avatar_options).length !== 0) {
          this.mouthType = this.data.avatar_options.mouthType
          this.eyebrow = this.data.avatar_options.eyebrow
          this.clotheType = this.data.avatar_options.clotheType
          this.clotheColor = this.data.avatar_options.clotheColor
          this.eyeType = this.data.avatar_options.eyeType
          this.accessoriesType = this.data.avatar_options.accessoriesType
          this.skinColor = this.data.avatar_options.skinColor
          this.hairColor = this.data.avatar_options.hairColor
          this.topType = this.data.avatar_options.topType
        }
      })
    },
  },
}
</script>
<style scoped>
.b-slider {
  margin: 0.5em 0;
}
#pic {
  max-width: 200px;
  margin: auto;
}
</style>
