<template>
  <div class="about">
    <div class="hero">
      <h4 class="title is-4">My Profile</h4>
    </div>
    <section
      style="padding: 10px; margin: auto; max-width: 500px"
      id="screenPage"
    >
      <div id="pic">
        <b-field label="Avatar" style="text-align: center;">
          <van-image
            @click.stop="editAvatar"
            width="100"
            height="100"
            fit="cover"
            lazy-load
            :src="data.image"
          />
        </b-field>
      </div>
      <b-field label="First Name">
        <b-input
          placeholder="First name"
          required
          v-model="data.first_name"
          type="text"
          min="3"
          max="40"
        ></b-input>
      </b-field>
      <b-field label="Last Name">
        <b-input
          placeholder="Last name"
          v-model="data.last_name"
          type="text"
          required
          min="3"
          max="40"
        ></b-input>
      </b-field>
      <b-field label="Username (display name)">
        <b-input
          placeholder="Username"
          type="text"
          v-model="data.username"
          required
          min="3"
          max="40"
        ></b-input>
      </b-field>
      <b-field label="Email">
        <b-input
          placeholder="Email"
          v-model="data.email"
          disabled
          required
          type="email"
        ></b-input>
      </b-field>
      <!-- <b-field>
        <b-input placeholder="Current Password" type="password"></b-input>
      </b-field>
      <b-field>
        <b-input placeholder="New Password" type="password"></b-input>
      </b-field> -->
      <!-- <b-field label="Push Notifications">
        <b-select placeholder="Push Notifications" rounded>
          <option :value="1">Yes</option>
          <option :value="0">No</option>
        </b-select>
      </b-field> -->
      <hr />
      <div>
        <b-button type="is-primary">Save</b-button>
      </div>

      <b-field></b-field>
    </section>
  </div>
</template>
<script>
import ApiService from '@/services/ApiService'
export default {
  data() {
    return {
      data: {},
    }
  },
  components: {},
  methods: {
    editAvatar() {
      this.$router.push('/members/avatar')
    },
    getUserData() {
      ApiService.registration.getUserData().then((resp) => {
        this.data = resp.data
        this.data.image += '?test='+Math.random()
      })
    },
  },
  mounted() {
    this.getUserData()
  },
}
</script>
<style scoped>
#pic {
  max-width: 200px;
  margin: auto;
}
</style>
